import React, {useState} from 'react'
import { useLocation, Link } from 'react-router-dom'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js'
import { Button, Card, CardBody, Spinner } from 'reactstrap'

import { Form, CheckboxField, TextField } from 'loxamtraining-form'


export default function CheckoutForm({ clientSecret, status } : {clientSecret: any, status: any}) {
  const [isLoading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()

  console.log(location.pathname)
  console.log(window.location.href)

  const handleSubmit = async (values: any, actions: any) => {
    actions.setStatus(null)
    console.log(values)
    setLoading(true)

    if (values.acceptTerm === false) {
      actions.setStatus("Vous devez accepter les modalités de paiement pour procéder au paiement")
      actions.setSubmitting(false)
      setLoading(false)
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    if (values.acceptTerm === true) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card:  elements.getElement(CardElement) || {token: ''},
          billing_details: {
            name: values.holder,
          },
        },
        return_url: window.location.href
      })

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error)
        console.log(result.error.message)

        result.error && result.error.message ? (
          actions.setStatus(result.error.message)
        ) : (
          actions.setStatus("Erreur lors de la demande de réinitialisation de mot de passe")
        )
        
        actions.setSubmitting(false)
        setLoading(false)
      } else {
        // The payment has been processed!
        if (result && result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          console.log("payment succeeded")
        }
      }
    }
  }

  const initialValues = {
    acceptTerm: false,
    holder: ""
  }

  return (
     <Form onSubmit={handleSubmit} initialValues={initialValues} hideSubmitButton>
      <Card className="mb-4">
        <CardBody>
             <CardElement options={{hidePostalCode: true, disabled: isLoading}}/>
        </CardBody>
      </Card>
      <TextField name="holder" label="Nom du titulaire de la carte" required disabled={isLoading} />
      <div className="ml-1">
        <CheckboxField name="acceptTerm" label="J'accepte les modalités de paiement" required disabled={isLoading}/>
      </div>
      <hr className="mb-4" />
      <Button color="primary" size="lg" block disabled={isLoading}>
          {
            isLoading ? (
              <React.Fragment>
               <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> 
                { status === "capture" && "Paiement en cours..."}
                { status === "deposit" && "Caution en cours..."}
              </React.Fragment>
            ) : (
              <span>
                { status === "capture" && "Procéder au paiement"}
                { status === "deposit" && "Procéder à la caution"}
              </span>
            )
          }
      </Button>
    </Form>
  )
}