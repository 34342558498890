import React, { useState, useEffect } from 'react'
import * as firebase from "firebase/app"
import 'firebase/firestore'
import {BrowserRouter, Route, useHistory, useParams, Switch} from 'react-router-dom'
import { Container, Row, Col, ListGroup, ListGroupItem, Card, CardBody, Button, Alert } from 'reactstrap'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import Skeleton from 'react-loading-skeleton'

import settings from './settings'

//Components
import CheckoutForm from './CheckoutForm'
import Footer from './components/Footer'

//Pages
import Legal from './pages/Legal'
import Privacy from './pages/Privacy'

import 'bootstrap/dist/css/bootstrap.min.css'

import defaultLogo from './assets/images/loxam-logo.svg'

//const stripePromise = loadStripe("pk_test_f1eclZyOddpVFsYMId9VhOuW002CdZRna3") //staging


const stripePromise = loadStripe("pk_live_pOsipm5Q0oMMJH43YHc3dtzp00MMP3paeh") //prod

// Initialize Firebase
const firebaseConfig = settings.firebaseConfig
firebase.initializeApp(firebaseConfig)

const displayAmount = (amount: string) => `${amount.toString().substring(0, amount.toString().length-2)},${amount.toString().slice(-2)}`


const TopContent = () => (
	<div className="py-5 text-center">
		<img className="d-block mx-auto mb-4" src={defaultLogo} alt="" height="25" />
		<h2>Paiement</h2>
		<p className="lead">Pour finaliser votre commande merci de procéder au paiement</p>
	</div>
)


const OrderDetail = ({ data } : {data: any}) => (
	<div className="mb-4">
		<h4 className="d-flex justify-content-between align-items-center mb-3">
    		<span className="text-muted">Votre commande</span>
    		{/*<span className="badge badge-secondary badge-pill">3 articles</span>*/}
  		</h4>
  		<ListGroup>
			<ListGroupItem className="d-flex justify-content-between">
				<span>Total (EUR)</span>
				<strong>{displayAmount(data.paymentIntent.amount)}€</strong>
			</ListGroupItem>
		</ListGroup>
	</div>
)

const OrderDetailLoading = () => (
	<div className="mb-4">
		<h4 className="d-flex justify-content-between align-items-center mb-3">
    		<span className="text-muted">Votre commande</span>
    		{/*<span className="badge badge-secondary badge-pill">...</span>*/}
  		</h4>
  		<ListGroup>
			<ListGroupItem className="d-flex justify-content-between">
				<span><Skeleton count={1} height={20} width={130} /></span>
				<strong><Skeleton count={1} height={20} width={130} /></strong>
			</ListGroupItem>
		</ListGroup>
	</div>
)



const CardForm = ({ data } : {data: any}) => (
	<Elements stripe={stripePromise}>
		<CheckoutForm  clientSecret={data.paymentIntent.client_secret} status={data.type} />
	</Elements>
)

const CardFormLoading = () => (
	<React.Fragment>
		<Card className="mb-4">
        	<CardBody>
           		<Skeleton count={1} height={20} />
        	</CardBody>
      	</Card>
      	<div className="ml-1">
      		<Skeleton count={1} height={20} />
      	</div>
      	<Button color="primary" size="lg" block disabled>Procéder au paiement</Button>
    </React.Fragment>
)

const Checkout = () => {
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState({} as any)


	const { id } = useParams()

	//const id = 'pi_1GYIjgDkbI9gmkR9teMMit9k_secret_s67DZ5TL0AN6Uk10xTqhyY9oJ'

	useEffect(() => {
		if (id) {
			//TODO: add to lib
			firebase.firestore().doc(`/payments/${id}`).onSnapshot((docSnap) => {
				const payment: any = Object.assign({id: docSnap.id}, docSnap.data())
				setData(payment)
				setLoading(false)
			})
		}
	}, [id])

	return (
 		<Container>
  			<Row>
  				{
  					isLoading ? (
  						<Col md={{ size: 6, offset: 3 }} className="mb-4">
							<TopContent />
							<OrderDetailLoading />
							<CardFormLoading />
						</Col>
  					) : (
  						<Col md={{ size: 6, offset: 3 }} className="mb-4">
							<TopContent />
							<OrderDetail data={data} />
							{data.status === 'created' && <CardForm data={data} />}
							{data.status === 'succeeded' && (
								<Alert color="success" className="text-center">
        							Merci votre paiement bien été effectué !
      							</Alert>
							)}
							{data.status === 'requires_capture' && (
								<Alert color="success" className="text-center">
        							Merci la caution a bien été effectuée !
      							</Alert>
							)}
						</Col>
  					)
  				}
			</Row>
			<Footer />
		</Container>
  	)
}


const App = () => {
 	return (
 		<BrowserRouter>
 			<Switch>
 				<Route exact path="/legal" component={Legal} />
 				<Route exact path="/:id" component={Checkout} />
 			</Switch>
		</BrowserRouter>
  	)
}

export default App
