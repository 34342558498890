import React from 'react'
import { Link } from 'react-router-dom'

import defaultLogo from '../assets/images/loxam-logo.svg'

const Footer = () => (
	<footer className="my-5 pt-5 text-muted text-center text-small">
		<p className="mb-1"><img className="d-block mx-auto mb-4" src={defaultLogo} alt="" height="15" /></p>
		<ul className="list-inline">
			<li className="list-inline-item"><Link to="/legal" className="text-secondary">Modalités de paiement et confidentialité</Link></li>
		</ul>
	</footer>
)


export default Footer