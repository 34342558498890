import React from 'react'
import { Container, Row, Col} from 'reactstrap'
import Footer from '../components/Footer'


const Legal = () => (
	<Container>
		<Row>
			<Col>
				<h3 className="mt-4 mb-4">Conditions spéciales concernant le paiement à distance sur Internet</h3>
				<h5 className="mb-3 mt-4">1.	Paiement de la location ou de l’achat</h5>
				<p>Le paiement de l’achat ou de la location d’équipements se fait sur Internet selon les données de paiement que vous fournissez lors de la validation de la commande.</p>
				<p>Le paiement de l’achat ou de la location d’équipements se fait sur Internet selon les données de paiement que vous fournissez lors de la validation de la commande.</p>
				<p>Le paiement se fera uniquement par carte bancaire.</p>
				<p>Loxam n’aura à aucun moment accès aux informations confidentielles concernant votre carte bancaire.</p>
				<p>Pour acquitter votre paiement, vous devez fournir votre numéro de carte bancaire, sa date d'expiration, le cryptogramme visuel de 3 ou 4 chiffres et le nom du titulaire de la carte.</p>
				<p>Pour chaque location ou achat de matériels, vos coordonnées de carte bancaire vous seront demandées car Loxam ne dispose ni ne conserve ces informations. (Stripe concerve les données le temps de la location)</p>
				<p>Pour l’utilisation de la carte bancaire, vous garantissez Loxam que vous êtes bien le propriétaire de la carte utilisée et que vous disposez des autorisations nécessaires pour l’utiliser.</p>
				<p>Loxam sera en droit de refuser toute commande anormale ou pour motifs légitimes conformément à l’article L122-1 du Code de la consommation.</p>
				<p>Si votre banque refuse le paiement de la location ou de l’achat, votre commande sera automatiquement annulée.</p>
				<p>Les paiements que vous effectuez par carte bancaire sur Internet sont sécurisés par les systèmes de protection de la société Stripe.</p>
				<p>Une fois le paiement effectué, un récapitulatif de paiement et de la commande vous sera automatiquement envoyé.</p>
				<p>Le récapitulatif comportera :</p>
				<ul>
					<li>la référence de la commande</li>
					<li>la référence du paiement</li>
					<li>le montant total de la commande, toutes taxes comprises</li>
				</ul>
				<p>Pour obtenir plus d’information sur le paiement sécurisé de notre prestataire Stripe, vous pouvez consulter le site https://stripe.com/help/ssl</p>
				
				<h5 className="mb-3 mt-4">2.	Collecte de données par Loxam et son prestataire de paiement</h5>
				<p>Dans le cadre de votre location ou achat auprès de Loxam, ainsi que lors de votre utilisation des services de paiement par téléphone ou par internet, des données à caractère personnel vous concernant sont collectées et traitées par nos services ainsi que par notre prestataire Stripe gérant l’application de paiement à distance par laquelle vous fournissez vos identifiants de carte de paiement.</p>
				<p>Certaines opérations de traitement ou certains stockages opérés par Stripe sont réalisés en dehors de l’Union Européenne et aux Etats-Unis. A cet effet, ce dernier a souscrit au Privacy Shield Framework UE-USA et au Privacy Shield Framework Suisse-USA.</p>
				<p>La politique de protection des données de Loxam est disponible sur le site www.Loxam.fr à la rubrique « protection des données personnelles » et a pour objet de vous informer notamment sur :</p>
				<ul>
					<li>la façon dont nous collectons et traitons vos données à caractère personnel</li>
					<li>les cookies pouvant être déposé en lien avec votre location ou achat</li>
				</ul>
				<p>Pour exercer vos droits sur le traitement de vos données personnelles, vous pouvez nous adresser un e-mail à l’adresse suivante :  service.juridique@loxam.fr.</p>
				<p>Les présentes conditions spéciales de paiement à distance sont soumises au droit français et viennent compléter les conditions générales interprofessionnelles et particulières de location de matériel d’entreprise sans opérateur Loxam disponible sur le site Loxam.fr ainsi que les conditions spéciales de location Loxam – période de confinement.</p>
				<p>De convention expresse et sous réserve de la législation impérative en vigueur, le tribunal de commerce de Lorient est compétent pour connaître de tout litige relatif aux présentes conditions, même en cas de pluralité de défendeurs ou appel en garantie ; le client dont le siège est situé hors de France accepte expressément cette attribution de compétence. </p>
			</Col>
		</Row>
		<Footer />
	</Container>
)

export default Legal